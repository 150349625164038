<template>
    <div>
        <div class="header">
            <img src="../assets/logo.png">
			<div class="tabs">
				<template v-for="(item,index) in tabs">
					<div :class="[active === item.id ? 'tab-active' : '']" :key="index" @click="tabsChange(item)">{{item.name}}</div>
				</template>
			</div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "HeaderContent",
		data() {
			return {
				active: 'TabContent1',
				tabs:[
					{id: 'TabContent1',name:'网站首页'},
					{id: 'TabContent2',name:'产品中心'},
					{id: 'TabContent3',name:'新闻资讯'},
					{id: 'TabContent4',name:'关于我们'}
				]
			}
		},
		methods:{
			tabsChange(item){
				this.active = item.id
				this.$emit('change',item.id)
			}
		}
	}
</script>

<style scoped>
.header {
    height: 90px;
    background: white;
	display: flex;
	align-items: center;
	justify-content: center;
}
img{
	height: 35px ;
}
.tabs{
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	color: #666666;
	gap: 94px;
	margin-left: 184px;
}
.tabs>div{
	cursor: pointer;
}
.tab-active{
	color: #E16B2F;
	font-weight: bold;
}
</style>
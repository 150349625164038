<template>
  <div class="footer-content">
    <div>Copyright © 湖南全数科技有限公司 All Rights Reserved.湘ICP备2023003879号 </div>
  </div>
</template>

<script>
export default {
  name: 'FooterContent'
}
</script>

<style scoped>
.footer-content{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  color: #ffffff;
  font-size: 13px;
  background: #454545;
  gap: 10px;
}
</style>
